var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-wh" },
    [
      _c("cite", [
        _vm._v("Custom working hours will override regular working hours."),
      ]),
      _c("div", { staticClass: "row mt-4 mb-2" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 text-center" },
          [
            _c(
              "CButton",
              {
                attrs: {
                  color: "success",
                  size: "sm",
                  variant: "outline",
                  shape: "pill",
                },
                on: { click: _vm.pushCwh },
              },
              [_c("CIcon", { attrs: { name: "cil-plus" } })],
              1
            ),
          ],
          1
        ),
        _vm._m(0),
        _vm._m(1),
      ]),
      _vm._l(_vm.formCwh, function (cwh, index) {
        return _c(
          "div",
          {
            staticClass: "row my-2 py-1 wh-row",
            class: {
              "table-danger rounded": cwh.invalid,
              "disable-item strikeout": cwh.deleted,
            },
          },
          [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "d-flex align-items-center flex-gap-2" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        size: "sm",
                        color: "danger",
                        variant: "ghost",
                        shape: "pill",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(cwh, index)
                        },
                      },
                    },
                    [_c("CIcon", { attrs: { name: "cil-trash" } })],
                    1
                  ),
                  _c("CInputCheckbox", {
                    staticStyle: { "min-width": "fit-content" },
                    attrs: {
                      label: "Off Day",
                      custom: "",
                      checked: cwh.is_off,
                    },
                    on: {
                      "update:checked": function ($event) {
                        return _vm.$set(cwh, "is_off", $event)
                      },
                    },
                  }),
                  _c("div", { staticClass: "form-group flex-fill" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: cwh.custom_date,
                          expression: "cwh.custom_date",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "date", min: _vm.today },
                      domProps: { value: cwh.custom_date },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(cwh, "custom_date", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-sm-3 col-6 px-sm-0" },
              [
                !cwh.is_off
                  ? _c("CSelectHour", {
                      attrs: { value: cwh.start_time },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(cwh, "start_time", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-3 col-6" },
              [
                !cwh.is_off
                  ? _c("CSelectHour", {
                      attrs: { value: cwh.end_time },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(cwh, "end_time", $event)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 col-6 px-sm-0" }, [
      _c("span", { staticClass: "label font-weight-bold" }, [
        _vm._v("Start Time"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 col-6" }, [
      _c("span", { staticClass: "label font-weight-bold" }, [
        _vm._v("End Time"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }